

@font-face {
    font-family: "Silka";
    src: url("../fonts/silka-medium-webfont.woff2") format("woff2"),
         url("../fonts/silka-medium-webfont.woff") format("woff");
  }
  

$module: 13px;
// $font-1: 'Montserrat', "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
$base-font-weight: 300;
$font-1: Silka, sans-serif;
$base-font-weight: 400;
// font-family: "bebas-neue",sans-serif;
// font-style: normal;
// font-weight: 400;
$font-2: Silka, sans-serif;

$font-3: Silka, sans-serif;


//                                                                           
//     ######  ## ##  ####    ###    ###   ####     #    ####   ## ##  ## ## 
//       ##    ## ##  ## ##  ## ##  ## ##  ## ##   ###   ## ##  ## ##  ## ## 
//       ##    ## ##  ## ##  ## ##  ##     ## ##  ## ##  ## ##  ## ##  ## ## 
//       ##     ###   ####   ## ##  #####  ####   ## ##  ####   #####   ###  
//       ##     ###   ##     ## ##  ## ##  ###    #####  ##     ## ##   ###  
//       ##     ###   ##     ## ##  ## ##  ####   ## ##  ##     ## ##   ###  
//       ##     ###   ##      ###    ####  ## ##  ## ##  ##     ## ##   ###  
//                                                                           
//                                                                           


///STYLE TEKSTOW


.font--1{
    font-family: $font-1 !important;
}

.font--2{
    font-family: $font-2 !important;
}

//HEADER 
$header-font: $font-2;
$header-line-height: 1.4;
$header-font-weight: 400;
$header-color: $secondary-color;

.header{
    &--1{
        // @include rfs(64);
        font-size:4rem;
        font-family: $header-font;
        line-height: $header-line-height;
        font-weight: $header-font-weight;
        color:$header-color;
        
    }
    &--2{
        font-size:3rem;
        font-family: $header-font;
        line-height: $header-line-height;
        font-weight: $header-font-weight;
        color:$header-color;
        

    }
    &--3{
        font-size:1.6rem;
        font-family: $header-font;
        line-height: $header-line-height;
        font-weight: $header-font-weight;
        color:$header-color;
         
    }
    &--4{
        font-size:1.2rem;
        font-family: $header-font;
        line-height: $header-line-height;
        font-weight: $header-font-weight;
        color:$header-color;
        
    }
    &--5{
        font-size:1rem;
        font-family: $header-font;
        line-height: $header-line-height;
        font-weight: $header-font-weight;
        color:$header-color;
         
    }
    &--6{
        font-size:0.8rem;
        font-family: $header-font;
        line-height: $header-line-height;
        font-weight: $header-font-weight;
        color:$header-color;
         
    }

}

//LEAD
$lead-font: $font-2;
$lead-line-height: 1.5;
$lead-font-weight: normal;
$lead-margin-bottom: 1.5rem;

.lead{
    &--1{
        
        font-size:2.5rem;
        font-family: $lead-font;
        line-height: $lead-line-height;
        font-weight: $lead-font-weight;
        margin-bottom: $lead-margin-bottom;
    }
    &--2{
       
        font-size:1.5rem;
        font-family: $lead-font;
        line-height: $lead-line-height;
        font-weight: $lead-font-weight;
        margin-bottom: $lead-margin-bottom;
    }
    &--3{
       
        font-size:1.2rem;
        font-family: $lead-font;
        line-height: $lead-line-height;
        font-weight: $lead-font-weight;
        margin-bottom: $lead-margin-bottom;
         
    }
    &--4{
       
        font-size:1rem;
        font-family: $lead-font;
        line-height: $lead-line-height;
        font-weight: $lead-font-weight;
        margin-bottom: $lead-margin-bottom;
        
    }
    &--5{
        font-size:0.8rem;
        font-family: $lead-font;
        line-height: $lead-line-height;
        font-weight: $lead-font-weight;
        margin-bottom: $lead-margin-bottom;    
    }
}



.text{
   $text-line-height: 1.6;
    &--1{
        font-size: 1.2rem;
        line-height: $text-line-height;
    }
    &--2{
        font-size: 1rem;
        line-height: $text-line-height;
    }
    &--3{
        font-size: 0.9rem;
        line-height: $text-line-height;
    }
    &--4{
        font-size: 0.8rem;
        line-height: $text-line-height;
    }
    &--5{
        font-size: 0.7rem;
        line-height: $text-line-height;
    }
    &--6{
        font-size: 0.6rem;
        line-height: $text-line-height;
    }
    
}



.capitalize, .uppercase{
    text-transform:uppercase;
    letter-spacing: 1px;
}

.letterspace{
    letter-spacing: 0.15em;
}


.center{
    text-align: center;
}

.left{
    text-align: left;
}

.right{
    text-align: right;
}


//
//      ##     ####   #  ##  ## ##  ####
//      ##      ##    ## ##  ## ##   ##
//      ##      ##    #####  ####    ##
//      ##      ##    #####  ###     ##
//      ##      ##    #####  ####    ##
//      ##      ##    ## ##  ## ##   ##
//      #####  ####   ##  #  ## ##  ####
//
//

// LINK STRZALKA

    .link--strzalka{
        color:$link-color;
        transition: color 0.2s ease;
        &:hover{
            opacity: 1;
             color: $link-hover-color;
        }
        &:hover::after{
            transform: translate(0.6em,0);
            
        }
        &:after{
            content:' →';
            transition: transform 0.2s ease;
            display: inline-block;
            transform: translate(0.2em,0);
            
        }
    }


// Link podkreslenie
   .link--podkreslenie{
        position: relative;
    }
    .link--podkreslenie:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      bottom: 0;
      left: 0;
      background-color: $primary-color;
      visibility: hidden;
      -webkit-transform: scaleX(0);
      transform: scaleX(0);
      transform-origin: left top;
      -webkit-transition: all 0.3s ease-in-out 0s;
      transition: all 0.3s ease-in-out 0s;
    }

    .link--podkreslenie:hover:before {
      visibility: visible;
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
    }
    
    .link--white{
    color:#fff;
    opacity:1;
    transition: all 200ms ease;
        &:hover{
         color:#fff;
          opacity:0.8;   
        } 
    }

//
//      ## ##  #####  ##     ####   #####  ####    ###
//      ## ##  ##     ##     ## ##  ##     ## ##  ## ##
//      ## ##  ##     ##     ## ##  ##     ## ##  ##
//      #####  ####   ##     ####   ####   ####    ###
//      ## ##  ##     ##     ##     ##     ###       ##
//      ## ##  ##     ##     ##     ##     ####   ## ##
//      ## ##  #####  #####  ##     #####  ## ##   ###
//
//

.text-truncate {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}



