
* {
    box-sizing: border-box;
}

html, body{
    margin:0;
    font-family: $font-1;
    font-weight: 500;
    background-color:$gray-light;
  
}

p,span,a,small,ul,ol, blockquote,address,
h1,
h2,
h3,
h4,
h5,
h6 {
-webkit-font-smoothing: antialiased;
}

//breakpoints as tilewind
//bez prefixu: 0-640
$sm: 640px;
$md: 768px;
$lg: 1024px;
$xl: 1280px;







html {
    font-size: 12px;
    color:$primary-color;
    @include breakpoint($sm){
        font-size: 13px;

       
    }
    @include breakpoint($md){
        font-size: 14px;

    }
    @include breakpoint($lg){
        font-size: 15px;
    }
    @include breakpoint($xl){
        font-size: 17px;
    }
  }




/* HELPER */

/* purgecss start ignore */

  body{
    // box-shadow: inset 0px 0px 5px 0px yellow;
    
    .czujnik::after{
        content: "initial";
        font-size: 12px;
        display: inline;
        margin-left:10px;
    }
  
   
    @include breakpoint($sm){
        // box-shadow: inset 0px 0px 5px 0px  orange;
        .czujnik::after{
            content: "sm";
        }
    }
    @include breakpoint($md){
        // box-shadow: inset 0px 0px 5px 0px  red;
        .czujnik::after{
            content: "md";
        }
       
    }
    @include breakpoint($lg){
        // box-shadow: inset 0px 0px 5px 0px  purple;
        .czujnik::after{
            content: "lg";
        }
        
    }
    @include breakpoint($xl){
        // box-shadow: inset 0px 0px 5px 0px blue;
        .czujnik::after{
            content: "xl";
        }
        
    }
  }
 /* purgecss end ignore */


//  html,body{
//     /*scrollr*/
//     height: 100% !important; 

//  }

.fluid-container{
    margin: 0 auto;
    width: calc(100% - 2.5rem);
    max-width: 1300px;

    @include breakpoint($lg){
    width: calc(100% - 4rem);
    
    }
}


span a, p a, address a, small a{
    color:$secondary-color;
}


// .home-feed__body--news, .home-feed__body{
//     text-align: justify;
//     hyphens: auto;

// }



