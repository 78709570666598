.news{
    margin-top:6.5rem;

    h1{
        font-size: 1.1rem;
        margin-bottom: 1rem;
        display: none;;
    }

    h2{
        font-size:2.2em;
        margin-bottom: 1rem;
    }

    p{
        font-size: 0.90rem;
        line-height: 1.7em;
        max-width: 75vw;
        margin-bottom: 1rem;
    }
    &__item__gallery{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 2%;
        margin:1rem 0;
    }
    &__item__image{
        width: 50%;
        @include breakpoint(medium){
            width: 32%;
        }
    }
    &__item__image img{
        margin-bottom: 0;
       
       
    }
    &__item__image h2{
        font-size: 0.65rem;
        line-height: 1.6;
        margin-bottom: 0.4rem;
        margin-top: 0.2rem;
    }

}