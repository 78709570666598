.bio{
    margin-top:4rem;

    h1{
        font-size: 2rem;
        margin-bottom: 1rem;
        
    }

    h2{

        font-size: 1.3rem;
        margin-bottom: 0.6rem;
        margin-top: 3rem;
    }

    p{
        font-size: 0.90rem;
        line-height: 1.7em;
        max-width: 75vw;
    }

    li{
        font-size: 0.90rem;
        line-height: 2em; 
        div{
            margin-right:1rem;
            display: inline-block;
            width: 35px;
        }
    }

}