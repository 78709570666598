
body{
  display: flex;
  flex-direction: column;
}
/* purgecss start ignore */

.home-lead{
  height:calc(90vh - 60px);
  display: flex;
  justify-content: center;
  align-items: center;
  @include breakpoint($sm){
    max-height: 800px;
    height:calc(95vh - 150px);
    padding-top: 15vh;
  }
  &__header{
    font-size:3.2rem;
    line-height:1.2;
    margin-top:5rem;
    @include breakpoint($lg){
      margin-top:-1rem;
      font-size:3.7rem;
    }
    span{
      color: $secondary-color;
    }
    small{
      font-size: 100%;
    }
  }
  &__tags{
   
    @include breakpoint($lg){
     
     
    }
    @include breakpoint($sm down){
      display: none;
     
    }
    li{
      margin-bottom:1rem;
      
      a{
        transition: 0.2s all ease;
      }
      a:hover{
        color:$secondary-color;
      }
    }
    
  } 
  &__arr{
    margin-top:3rem;
    margin-left:0.5rem;
    width: 100%;
   
    height: 80px;
    color: $white;
    font-size: 0.7rem;
    letter-spacing: 3px;
    font-weight: 500;
 
    z-index: 2;
     cursor:pointer;
     transition: all .2s ease;
      
     @include breakpoint($lg){

     }
     &:hover{
      transform: translateY(7%);
     }
    a{
        color:  $primary-color;
        display: block; 
    }
   
    svg{
        width:7vw;
        height:7vw;
        @include breakpoint($md){
           width:2.2vw; 
           height:2vw;
        }
      & path{
        fill: $primary-color;
      }
        color: $primary-color;
        
        height: 15px;
        display: block;
        // margin:0 auto;
        position: relative;
        animation: fontbulger 1.5s infinite cubic-bezier(0.445, 0.05, 0.55, 0.95);
    }
    img{
      height: 15px;
    }
    @keyframes fontbulger {
        0%, 100% {
            top:10px;
        }
        50% {
            top:30px;
        }
    }

    }
}
/* purgecss end ignore */
.home-feed{

  position: relative;
  $gray-text: #999;


  &__article{
   margin-bottom: 6em;
    @include breakpoint($md){
      margin-bottom: 8rem;
    }
  }

  &__article--news{
  
    margin-top: 40vw;
    margin-bottom: 6rem;
    position: relative;
    @include breakpoint($md){
      margin-top: 0;
      margin-bottom: 8rem;
    }
  }

  &__article--news:hover{
 
      a .home-feed__header--news{
        color:$secondary-color;
      }
      .home-feed__body--news{
        // color:$secondary-color;
      }
      a .home-feed__img--news{
        transform: scale(1.01);
        transition: transform cubic-bezier(0.22, 1, 0.36, 1) 1.8s ;
      }
  }



  &__article:hover{
 
    a .home-feed__header{
      color:$secondary-color;
    }
    .home-feed__body{
      //  color:$secondary-color;
    }
    a .home-feed__img{
      transform: scale(1.01);
      transition: transform cubic-bezier(0.22, 1, 0.36, 1) 1.8s ;
    }
    .home-feed__tag{
      // color:$secondary-color;
    }
}


  &__img{
    transition: transform cubic-bezier(0.22, 1, 0.36, 1) 0.6s;
    }

    &__img--news{
      width: 50vw;
      margin-top: -30vw;
      max-width: initial;
      transition: transform cubic-bezier(0.22, 1, 0.36, 1) 0.6s ;
      @include breakpoint($md){
        width: initial;
        margin-top: 0;
        max-width: 100%;
      }
    }

  &__tag--news{
    font-size:0.8rem;
    margin-bottom: 0.4rem;
    color: $gray-text;
    position: absolute;
  left:37vw;
    margin-top:-2rem;
    display: inline-block;
    transition: color cubic-bezier(0.22, 1, 0.36, 1) 0.4s ;
    @include breakpoint($sm){
      left:13vw;
    }
    @include breakpoint($md){
      position: static;
      margin-bottom:0.7rem;
      margin-top:0rem;
    }
  }

  &__tag{
    font-size:1rem;
    display:block;
    color: $gray-text;
    margin-bottom:0.4rem;
    transition: color cubic-bezier(0.22, 1, 0.36, 1) 0.4s ;

     @include breakpoint($lg){
      font-size:0.8rem;
      margin-bottom:0.6em;
    }
 
  }

 

  &__header{
    font-size:2.6rem;
    margin-bottom: 0.3em;
    line-height: 1.1;
    transition: color cubic-bezier(0.22, 1, 0.36, 1) 0.4s ;
  }
  

  &__header--news{
    font-size:3.8rem;
    margin-bottom: 0.2em;
    line-height: 1.1;
    transition: color cubic-bezier(0.22, 1, 0.36, 1) 0.4s ;
  }

  &__body{
    font-size:1.2rem;
    line-height: 1.5;
    transition: color cubic-bezier(0.22, 1, 0.36, 1) 0.4s ;
    @include breakpoint($md){
      font-size:1.1rem;
    }
    @include breakpoint($lg){
      font-size:1rem;
    }
  }

  &__body--news{
    font-size:1.2rem;
    line-height: 1.6;
    transition: color cubic-bezier(0.22, 1, 0.36, 1) 0.4s ;
    @include breakpoint($md){
      font-size:1.2rem;
    }
    @include breakpoint($lg){
      font-size:1.2rem;
    }
  }
  
}

