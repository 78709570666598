.oferta{
padding-top:6rem;
  
}

.kompetencje{

  h2{
    font-size: 1rem;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    margin-bottom:1.5rem;
    margin-top:1rem;
    @include breakpoint($md){
      font-size: 0.75rem;
    }
  }
  h3{
    font-size: 1.1rem;
   
    margin-bottom:1rem;
    font-size:1.3rem;
    @include breakpoint($md){
      font-size: 1rem;
    }
  }
  ul{
    margin-bottom:3.5rem;
    @include breakpoint($md){
      margin-bottom:3rem;
    }
  }

  li{
    font-size: 1.3rem;
    margin-bottom:0.6rem;
    @include breakpoint($md){
      font-size: 0.85rem;
      margin-bottom:0.5rem
    }
    @include breakpoint($lg){
      font-size: 0.80rem;
      margin-bottom:0.7rem
    }
  }
  
}

.oferta-sekcje{

  &__cyfra{
    font-size:4.1rem;
    line-height: 1em;
    margin-right: 0.5rem;
    color: $secondary-color;
    display: block;
    text-align: right;
    @include breakpoint($lg){
    font-size:5.1rem;
    }
  }

  h2{
    font-size: 1.6rem;
    margin-bottom:1.1em;
    line-height:1.2em;
   
    @include breakpoint($md){
      font-size: 2.1rem;
      margin-bottom:1.3rem;
    }
  }

  p{
    font-size: 1.4rem;
    margin-bottom:1.2rem;
    @include breakpoint($md){
      font-size: 1.1rem;
      margin-bottom:1.2rem;
    }
  }

  &__pytanie{
    display: inline-block;
    margin-bottom:6rem;
    font-size: 1.1rem;
    line-height:1.6;
    display:block;
    margin-top:2rem;
    color:$secondary-color;
    @include breakpoint($md){
      font-size: 0.95rem;
    }
    @include breakpoint($lg){
      font-size: 0.85rem;
    }
    
    
  }
}

@include breakpoint($sm){
  max-height: 800px;
  height:calc(95vh - 150px);
  padding-top: 15vh;
}