.prace-header{
   
    @include breakpoint(medium){
        margin: 4rem 4rem 1rem 4rem;
    }
}

.prace {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 0;
    max-width: 1300px;
    margin: 100px auto 0 auto;
    @include breakpoint(medium){
        margin: 0 3rem;
    }

    &__item{
        position: relative;
        margin: 2rem 4rem;
        flex: 1 1 auto;
        padding-bottom:1.4rem;
        
   
       
        @include breakpoint(medium){
            margin: 1.2rem;
            max-width: 50vw;
        }
        &--dostepne{
            padding-bottom:2.5rem;  
        }
        &:last-child, &:nth-last-child(2){
            flex-grow:0;
            @include breakpoint(medium){
              max-height: 600px;
            }
           
            
        }

        &__image{
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
          &__caption{
            position:absolute;
            bottom:0px;
           
            h2,p{
                font-size: 0.8rem;
                line-height: 1.6;
                @include breakpoint(medium){
                    font-size: 0.65rem;
                }
            }

          }
      }
    
  }
  
  
  
